/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */
import * as React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import format from 'date-fns/format';
import { useGetDocumentsQuery } from '../../../api/appApi';
import Chip from '../../../components/Chip/Chip';
import TableContentPlaceholder from '../../../components/Table/TableContentPlaceholder';
import DocumentsIcon from '../../../icons/documents.svg';
import useMessages from '../../../hooks/useMessages';
import SpinnerItem from '../../../components/Spinner/SpinnerItem';
import TypeBadge from '../../../components/TypeBadge/TypeBadge';
import Card from '../../../components/Card/Card';

const DocumentsWidget = () => {
  const getMessage = useMessages();
  const navigate = useNavigate();

  const { data, isLoading, isSuccess } = useGetDocumentsQuery();
  const documents = data ? data.documents.slice(0, 3) : [];

  return (
    <div className="col-start-1 col-span-full md:col-start-2 md:col-span-1 lg:col-start-9 lg:col-span-4">
      <div className="col-span-12 flex justify-between">
        <p className="text-header-xs mb-2 inline-block">{getMessage('dashboard.widget.documents', {number: data?.metadata?.count})}</p>
        <button onClick={() => navigate('/dokumenty/nowy', { state: { returnTo: '/' } })} type="button" className="text-main bg-background-white text-xs rounded-sm font-bold hover:text-hover hover:underline px-4 mb-1">
          {getMessage('dashboard.widget.documents.addDocument')}
        </button>
      </div>
      <Card className="col-span-12 w-full grid grid-cols-1 gap-1 !p-5">
        {isLoading && [...Array(3)].map((_, index) => (
          <SpinnerItem
            key={index}
            containerStyles="first:h-[92px] last:h-[92px] h-[109px] md:h-[59px] md:last:h-[59px] md:first:h-[59px] w-full border-b last:border-b-0 py-4 first:pt-0 last:pb-0 flex gap-4"
          />
        ))}
        {isSuccess && (
          documents.length === 0
            ? <TableContentPlaceholder />
            : documents.map(({
              id, title, creationDate, documentNumber,
            }) => (
              <li
                className="w-full border-b last:border-b-0 py-4 first:pt-0 last:pb-0 flex gap-4
                hover:cursor-pointer hover:bg-gray-50 shadow-gray
                focus-within:border focus-within:border-hover relative"
              >
                <TypeBadge className="flex-none" badgeStyle="red">
                  <DocumentsIcon />
                </TypeBadge>
                <div className="flex-grow md:flex md:justify-between md:gap-4 truncate">
                  <div className="flex flex-col md:flex-grow truncate">
                    <p className="text-typography-black truncate text-title-semibold">
                      {title}
                    </p>
                    <p className="text-text-md-medium text-typography-dark truncate">{documentNumber}</p>
                  </div>
                  <Chip chipStyle="gray" className="mobile:mt-2 md:flex-none w-fit my-0">
                    {format(new Date(creationDate), 'dd-MM-yyyy')}
                  </Chip>
                </div>
                <Link
                  to={`/dokumenty/${id}`}
                  state={{ returnTo: '/' }}
                  aria-label={`Przejdź do szczegółów dokumentu nr ${documentNumber} ${title}`}
                  className='after:absolute after:content-[""] after:h-full after:w-full after:left-0 after:top-0'
                />
              </li>
            ))
        )}
      </Card>
    </div>
  );
};

export default DocumentsWidget;
