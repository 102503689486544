import * as React from 'react';
import format from 'date-fns/format';
import { useEffect } from 'react';
import {
  useGetFullUserAccountQuery,
  useGetSettlementsVenuesQuery,
} from '../../../api/appApi';
import ResponsiveTable, {
  ResponsiveTableBody,
  ResponsiveTableBodyRow,
  ResponsiveTableHeader,
} from '../../../components/Table/ResponsiveTable';
import { TableId, selectSettlementsTenantId } from '../../../store/appSlice';
import { TableBodyCell } from '../../../components/Table/Table';
import Chip from '../../../components/Chip/Chip';
import PaginationControls from '../../../components/Table/PaginationControls';
import { usePermissions } from '../../../hooks/usePermissions';
import usePagination from '../../../hooks/usePagination';
import useMessages from '../../../hooks/useMessages';
import useFilters from '../../../hooks/useFilters';
import useHomeownerAssociationsMode from '../../../hooks/useHomeownerAssociationsMode';
import { useAppDispatch } from '../../../hooks/reduxHooks';
import useSortParams from '../../../hooks/useSortParams';

interface Props {
  tab: 'all' | 'active',
}

const SettlementsVenueList = ({ tab }: Props) => {
  const getMessage = useMessages();
  const hoaMode = useHomeownerAssociationsMode();
  const { isModuleEnabled } = usePermissions();
  const dispatch = useAppDispatch();
  const [selectedPage, setSelectedPage] = usePagination('settlementsVenuesList');
  const { filterValues } = useFilters('settlementsVenuesList');
  const { sortParamsValues } = useSortParams('settlementsVenuesList');
  const filters = { ...filterValues };
  const { data: user } = useGetFullUserAccountQuery();

  if (tab === 'active') filters.residency_active = 'true';

  useEffect(() => {
    setSelectedPage(1);
  }, [tab]);

  const { data } = useGetSettlementsVenuesQuery({
    ...filters,
    ...sortParamsValues,
    page: selectedPage.toString(),
  });

  const { checkPermission, checkRoleType } = usePermissions();

  const handleClick = (venueId: string, tenantId: string) => {
    if (user) dispatch(selectSettlementsTenantId({ userId: user?.id, selectedSettlementsTenantId: tenantId }));
  };

  checkRoleType('worker');
  checkPermission('settlements_balance_list');

  const ids = data?.venues.map((venue) => venue.venueId) || [];
  const columnLabels = [
    { label: 'settlements.venuesList.columns.tenantId', id: 'main_tenant_external_id' },
    { label: 'settlements.venuesList.columns.name', id: 'tenants_tenants.name' },
    { label: 'settlements.venuesList.columns.code', id: 'tenants_venues.code' },
    { label: 'settlements.venuesList.columns.city' },
    { label: 'settlements.venuesList.columns.address' },
    { label: 'settlements.venuesList.columns.residencyDates' },
    {
      label: hoaMode
        ? 'settlements.venuesList.columns.homeownerAssociation'
        : 'settlements.venuesList.columns.membership',
    },
  ];
  return (
    <>
      <ResponsiveTable className="col-span-full">
        <ResponsiveTableHeader ids={ids} columnLabels={columnLabels} tableName={'settlementsVenuesList' as TableId} />
        <ResponsiveTableBody>
          {data?.venues.map(({
            tenantId, venueId, city, address, code, homeownerAssociation, residencyFrom, residencyUntil, tenant, tenantExternalId, membership,
          }) => (
            <ResponsiveTableBodyRow
              key={tenantId + venueId + residencyFrom + residencyFrom}
              className="border-b last:border-b-0 border-separate"
              clickable={({
                href: isModuleEnabled('payments') ? `/rozrachunki/platnosci?venueId=${venueId}&tenantId=${tenantId}` : `/rozrachunki/analiza_salda?venueId=${venueId}&tenantId=${tenantId}`,
                onClick: () => handleClick(venueId, tenantId),
                ariaLabel: `Przejdź do rozrachunków dla lokalu ${code} ${address} dla lokatora ${tenant}`,
              })}
            >
              <TableBodyCell>{tenantExternalId}</TableBodyCell>
              <TableBodyCell>{tenant}</TableBodyCell>
              <TableBodyCell>{code}</TableBodyCell>
              <TableBodyCell>{city}</TableBodyCell> 
              <TableBodyCell>{address}</TableBodyCell>
              <TableBodyCell>
                <Chip chipStyle="ghost" className="w-fit">
                  {residencyFrom && format(new Date(residencyFrom), 'dd-MM-yyyy')}
                  {residencyUntil && ` - ${format(new Date(residencyUntil), 'dd-MM-yyyy')}`}
                </Chip>
              </TableBodyCell>
              {hoaMode ? (
                <TableBodyCell>
                  {homeownerAssociation}
                </TableBodyCell>
              ) : (
                <TableBodyCell>
                  {membership && (
                    <Chip chipStyle="success" className="w-fit">
                      {getMessage('settlements.venuesList.member')}
                    </Chip>
                  )}
                </TableBodyCell>
              )}
            </ResponsiveTableBodyRow>
          ))}
        </ResponsiveTableBody>
      </ResponsiveTable>
      {data?.metadata && (
        <PaginationControls
          className="col-span-full"
          selectedPage={selectedPage}
          setSelectedPage={setSelectedPage}
          paginationMetadata={data.metadata}
        />
      )}
    </>
  );
};

export default SettlementsVenueList;
