import {
  ApiTask,
  ApiTaskCommission,
  ApiTaskComment,
  ApiTasksResponse,
  FullApiTask,
  FullTask,
  Task,
  TaskComment,
  TaskInputs,
  Tasks,
  TaskStatuses,
  ApiTaskStatuses, FullTaskStatus,
  TaskCommission,
  ApiPortalProServiceResponse,
  PortalProService,
} from './tasksTypes';
import { CommentFormData } from '../../../components/CommentsList/CommentForm/CommentForm';

export const translateTask = (apiTask: ApiTask): Task => ({
  id: apiTask.id,
  status: apiTask.status,
  createdAt: apiTask.created_at,
  lastCommentAt: apiTask.last_comment_at,
  taskNumber: apiTask.task_number,
  title: apiTask.title,
  venueAddress: apiTask.venue_address,
  venueCode: apiTask.venue_code,
  venueCity: apiTask.venue_city,
  venuePostalCode: apiTask.venue_postal_code,
  contactEmail: apiTask.contact_email,
  contactPhoneNumber: apiTask.contact_phone_number,
  homeownerAssociationCode: apiTask.homeowner_association_code,
  homeownerAssociationName: apiTask.homeowner_association_name,
  creator: apiTask.creator,
  creatorCode: apiTask.creator_external_id,
  realizer: apiTask.realizer,
  realizerCode: apiTask.realizer_external_id,
  estimatedStartTime: apiTask.estimated_start_time,
  estimatedFinishTime: apiTask.estimated_finish_time,
  startedAt: apiTask.started_at,
  finishedAt: apiTask.finished_at,
  category: apiTask.category,
  ticketNumber: apiTask.ticket_number,
  documentNumber: apiTask.document_number,
});

const translateComment = (apiComment: ApiTaskComment): TaskComment => ({
  author: apiComment.author,
  content: apiComment.content,
  createdAt: apiComment.created_at,
  attachments: apiComment.attachments.map((apiAttachment) => ({
    contentType: apiAttachment.content_type,
    filename: apiAttachment.filename,
    path: apiAttachment.path,
    description: apiAttachment.description,
  })),
});

export const translateCommissions = (apiCommission: ApiTaskCommission): TaskCommission => ({
  id: apiCommission.id,
  internalCommissionStatus: apiCommission.internal_commission_status,
  provider: apiCommission.provider,
  portalPro: {
    jobTrackingUrl: apiCommission.portal_pro?.job_tracking_url,
    externalId: apiCommission.portal_pro?.external_id,
    status: apiCommission.portal_pro?.status
  }
});

export const translateFullApiTask = (fullApiTask: FullApiTask): FullTask => ({
  attachments: fullApiTask.attachments.map((attachment) => ({
    contentType: attachment.content_type,
    filename: attachment.filename,
    path: attachment.path,
    description: attachment.description,
  })),
  category: { id: fullApiTask.category?.id, name: fullApiTask.category?.name },
  comments: fullApiTask.comments?.map((apiComment) => translateComment(apiComment)) || [],
  commissions: fullApiTask.task_commissions?.map((apiCommission) => translateCommissions(apiCommission)) || [],
  createdAt: fullApiTask.created_at,
  creator: fullApiTask.creator,
  creatorCode: fullApiTask.creator_external_id,
  description: fullApiTask.description,
  estimatedStartTime: fullApiTask.estimated_start_time,
  estimatedFinishTime: fullApiTask.estimated_finish_time,
  startedAt: fullApiTask.started_at,
  finishedAt: fullApiTask.finished_at,
  homeownerAssociationCode: fullApiTask.homeowner_association_code,
  homeownerAssociationName: fullApiTask.homeowner_association_name,
  id: fullApiTask.id,
  lastCommentAt: fullApiTask.last_comment_at,
  realizer: fullApiTask.realizer,
  realizerCode: fullApiTask.realizer_external_id,
  status: { id: fullApiTask.status?.id, name: fullApiTask.status?.external_name },
  taskNumber: fullApiTask.task_number,
  ticketId: fullApiTask.ticket?.id,
  documentId: fullApiTask.document?.id,
  title: fullApiTask.title,
  venueAddress: fullApiTask.venue_address,
  venueCode: fullApiTask.venue_code,
  venueCity: fullApiTask.venue_city,
  venuePostalCode: fullApiTask.venue_postal_code,
  contactEmail: fullApiTask.contact_email,
  contactPhoneNumber: fullApiTask.contact_phone_number
});

export const translateApiTasks = (apiTaskResponse: ApiTasksResponse): Tasks => ({
  tasks: apiTaskResponse.tasks.map((apiTask) => translateTask(apiTask)),
  metadata: apiTaskResponse.metadata,
});

export const translateTaskToFormData = (data: TaskInputs): FormData => {
  const form = new FormData();
  form.append('task[title]', data.title);
  form.append('task[description]', data.description);
  if (data.ticket_id) {
    form.append('task[ticket_id]', data.ticket_id);
  }
  if (data.document_id) {
    form.append('task[document_id]', data.document_id);
  }
  form.append('task[category_id]', data.category_id);
  form.append('task[status_id]', data.status_id);
  if (data.realizer_id) form.append('task[realizer_id]', data.realizer_id);
  if (data.estimated_start_time) form.append('task[estimated_start_time]', data.estimated_start_time);
  if (data.estimated_finish_time) form.append('task[estimated_finish_time]', data.estimated_finish_time);
  if (data.started_at) form.append('task[started_at]', data.started_at);
  if (data.finished_at) form.append('task[finished_at]', data.finished_at);

  data.attachments.forEach((attachment, index) => {
    if (attachment.file?.length) {
      form.append(`task[attachments][${index}][file]`, attachment.file[0]);
      form.append(`task[attachments][${index}][description]`, attachment.description);
    }
  });
  return form;
};

export const translateTaskCommentToFormData = (data: CommentFormData): FormData => {
  const form = new FormData();
  form.append('comment[content]', data.content);
  form.append('comment[public]', 'false');
  data.attachments.forEach((attachment, index) => {
    if (attachment.file?.length) {
      form.append(`comment[attachments][${index}][file]`, attachment.file[0]);
      form.append(`comment[attachments][${index}][description]`, attachment.description);
    }
  });
  return form;
};

export const translateTaskStatus = ({ statuses }: ApiTaskStatuses): TaskStatuses => ({
  statuses: statuses.map((status) => ({
    id: status.id,
    name: status.external_name,
  })),
});

export const translateFullTaskStatus = ({ statuses }: ApiTaskStatuses): FullTaskStatus[] => statuses.map((status) => ({
  id: status.id,
  objectType: 'task',
  name: status.name,
  active: status.active,
  internalName: status.internal_name,
  externalName: status.external_name,
  description: status.description,
}));

export const translateServices = ({commission_portal_pro_services} : ApiPortalProServiceResponse): PortalProService[] =>(
  commission_portal_pro_services.map((service) =>({name: service.name, code: service.service_code, id: service.service_id})) 
)