import { PaginationMetadata } from '../../paginationTypes';

export interface ApiTasksResponse {
  tasks: ApiTask[],
  metadata: PaginationMetadata,
}

export interface ApiTaskResponse {
  task: FullApiTask,
}

export type ApiPortalProServiceGroupResponse = {
  commission_portal_pro_service_groups: ApiPortalProServiceGroup[]
}

export type ApiPortalProServiceResponse = {
  commission_portal_pro_services: ApiPortalProService[]
}

export type ApiPortalProServiceGroup = {
  service_group_code: string,
  name: string,
  services: ApiPortalProService[]
}

export type ApiPortalProService = {
  service_id: string,
  service_code: string,
  name: string
}

export interface Tasks {
  tasks: Task[],
  metadata: PaginationMetadata,
}

export type ApiTask = {
  id: string,
  status: string,
  created_at: string,
  last_comment_at: string,
  task_number: string,
  title: string,
  venue_address: string,
  venue_code: string,
  venue_postal_code: string,
  venue_city: string,
  contact_email: string,
  contact_phone_number: string,
  homeowner_association_code: string,
  homeowner_association_name: string,
  comments: ApiTaskComment[],
  task_commissions: ApiTaskCommission[],
  category: string,
  creator: string,
  creator_external_id: string,
  realizer: string,
  realizer_external_id: string,
  estimated_start_time: string,
  estimated_finish_time: string,
  started_at: string,
  finished_at: string,
  ticket_number: number,
  document_number: string
};

export interface ApiTaskComment {
  attachments: ApiAttachment[]
  author: string,
  content: string,
  created_at: string,
  public: boolean,
}

export interface ApiTaskCommission {
  id: string,
  portal_pro: ApiPortalPro,
  internal_commission_status: string,
  provider: string,
}

export interface ApiPortalPro {
  status: string,
  external_id: string,
  job_tracking_url: string,
}

export interface ApiAttachment {
  description: string,
  filename: string,
  path: string,
  content_type: string,
}

export type FullApiTask = Omit<ApiTask, 'status' | 'category'> & {
  attachments: ApiAttachment[],
  category: TaskCategory,
  comments: ApiTaskComment[],
  commissions: TaskCommission[],
  created_at: string,
  description: string,
  status: ApiTaskStatus,
  ticket: {
    id: string,
  },
  document: {
    id: string,
  }
};

export type Task = {
  id: string,
  status: string,
  createdAt: string,
  lastCommentAt: string,
  taskNumber: string,
  title: string,
  venueAddress: string,
  venueCode: string,
  venuePostalCode: string,
  venueCity: string,
  contactPhoneNumber: string,
  contactEmail: string,
  homeownerAssociationCode: string,
  homeownerAssociationName: string,
  category: string,
  creator: string,
  creatorCode: string,
  realizer: string,
  realizerCode: string,
  estimatedStartTime: string,
  estimatedFinishTime: string,
  startedAt: string,
  finishedAt: string,
  ticketNumber: number,
  documentNumber: string,
};

export type FullTask = Omit<Task, 'status' | 'category'> & {
  description: string,
  attachments: TaskAttachment[],
  ticketId?: string,
  comments: TaskComment[],
  commissions: TaskCommission[],
  status: TaskStatus,
  category: TaskCategory,
  documentId?: string,
};

export type TaskComment = {
  author: string,
  content: string,
  createdAt: string,
  attachments: TaskAttachment[]
};

export type TaskAttachment = {
  description: string,
  filename: string,
  path: string,
  contentType: string,
};

export type TaskCommission = {
  id: string,
  provider: string,
  internalCommissionStatus: string,
  portalPro?: PortalPro
}

export type PortalPro = {
  externalId: string,
  status: string, 
  jobTrackingUrl: string,
}

export type PortalProServiceGroup = {
  name: string,
  code: string,
  services: PortalProService[]
}

export type PortalProService = {
  name: string,
  code: string,
  id: string
}

export type ApiPostTask = {
  task: FormData,
  id: string,
};

export type Attachment = {
  file: FileList | undefined,
  description: string
};

export type TaskCategory = {
  id: string,
  name: string,
};

export type ApiTaskCategories = {
  task_categories: TaskCategory[]
};

export type ApiTaskStatus = {
  id: string,
  object_type: string,
  name: string,
  active: boolean,
  internal_name: string,
  external_name: string,
  description: string,
};

export interface FullTaskStatus {
  id: string,
  objectType: string,
  name: 'open' | 'completed' | string,
  active: boolean,
  internalName: string,
  externalName: string,
  description: string,
}

export type ApiTaskStatuses = {
  statuses: ApiTaskStatus[]
};

export type TaskStatus = TaskCategory;

export interface TaskStatuses {
  statuses: TaskStatus[]
}

export interface ApiPostTaskComment {
  id: string,
  payload: FormData
}

export interface ApiPostTaskCommission {
  id: string,
  payload: {
    commission:
     {
      provider: string,
      service_id: string,
      description: string
      street_address: string,
      postal_code: string,
      contact_phone: string,
      contact_email: string,
      city: string,
      deadline?: Date,
      offer_limit?: Date
    }}
}

export type ApiTaskCommissionStatusResponse = {
  commission: ApiTaskCommissionStatus
}

export type ApiTaskCommissionStatus = {
  id: string,
  provider: string,
  internal_commission_status: string,
  portal_pro: ApiPortalProStatus
}

export type ApiPortalProStatus = {
  external_id: string,
  status: string,
  job_tracking_url: string,
}

export type TaskCommissionStatus = {
  provider: string,
  internalCommissionStatus: string,
  portalPro: PortalProStatus
}

export type PortalProStatus = {
  externalId: string,
  status: string,
  jobTrackingUrl: string,
}

export type postTaskCommissionsPayload = {
  serviceId: string,
  description: string,
  streetAddress: string,
  postalCode: string,
  city: string,
  contactPhone: string,
  contactEmail: string,
  deadline?: Date,
  offerLimit?: Date
}

export const taskDefaultColumnIds = [
  'status', 'created_at', 'last_comment_at', 'task_number', 'title', 
  'venue_address', 'homeowner_association_code', 'category', 'realizer',
];
export const taskCustomizableColumnIds = taskDefaultColumnIds.concat([
  'venue_code', 'homeowner_association_name', 'creator_external_id', 'creator',
  'estimated_start_time', 'started_at', 'estimated_finish_time', 'finished_at',
  'realizer_external_id', 'ticket_number', 'document_number'
]);

export type TaskColumnId = typeof taskCustomizableColumnIds[number] | typeof taskCustomizableColumnIds[number];

export interface ApiPostColumnsSettings {
  columns?: (TaskColumnId | undefined)[]
}

export interface ApiColumnsSettings {
  selected_columns: TaskColumnId[]
}

export interface TaskInputs {
  title: string,
  ticket_id?: string,
  document_id?: string,
  category_id: string,
  realizer_id: string,
  status_id: string,
  description: string,
  attachments: Attachment[],
  estimated_start_time: string,
  estimated_finish_time: string,
  started_at: string,
  finished_at: string,
  tenant: string,
  venue: string,
  homeownerAssociation: string,
}

export interface ApiPostChangeTaskData {
  tasks: {
    [id: string]: {
      status_id?: string,
      category_id?: string
      realizer_id?: string | null,
    }
  }
}
