import * as React from 'react';
import { useIntl } from 'react-intl';
import { useMemo } from 'react';
import { EmployeeShowTicketData } from '../../../../api/modules/tickets/ticketsTypes';
import useMessages from '../../../../hooks/useMessages';
import useHomeownerAssociationsMode from '../../../../hooks/useHomeownerAssociationsMode';
import { FullTask, Task } from '../../../../api/modules/tasks/tasksTypes';
import { tasksLabel } from '../../../../../utils/common';

interface Props {
  task: FullTask,
  ticket: EmployeeShowTicketData,
}



const ConnectedTicketSection = ({ task, ticket }: Props) => {
  const intl = useIntl();
  const getMessage = useMessages();
  const hoaMode = useHomeownerAssociationsMode();

  const connectedTasks = useMemo(
    () => ticket?.tasks?.filter((ticketsTask: Task) => ticketsTask.id !== task.id) || [],
    [ticket, task],
  );

  return (
    <>
      <section>
        <table className="text-text-md-semibold text-typography-dark w-full mt-4 tablet:mt-0">
          <tbody>
            <tr>
              <td
                colSpan={2}
                className="pt-2 tablet:pt-0"
              >
                <a
                  className="text-main cursor-pointer underline hover:text-hover"
                  href={`/zgloszenia/${ticket.id}`}
                  aria-label={intl.formatMessage(
                    { id: 'tasks.task.goToTicket' },
                    { ticketNumber: ticket.ticketNumber },
                  )}
                >
                  {`${getMessage('tasks.task.ticket')} nr ${ticket.ticketNumber}`}
                </a>
              </td>
            </tr>
            <tr>
              <td className="pt-2">{getMessage('tasks.task.ticket.creator')}</td>
              <td className="text-text-md-medium pt-2 text-right">{ticket.reporter}</td>
            </tr>
            {hoaMode && ticket.homeownerAssociationName && (
            <tr>
              <td className="pt-2">{getMessage('tasks.task.ticket.homeownerAssociation')}</td>
              <td className="text-text-md-medium pt-2 text-right">{ticket.homeownerAssociationName}</td>
            </tr>
            )}
            {ticket.venueAddress && (
            <tr>
              <td className="pt-2">{getMessage('tasks.task.ticket.venue')}</td>
              <td className="text-text-md-medium pt-2 text-right">{ticket.venueAddress}</td>
            </tr>
            )}
          </tbody>
        </table>
      </section>
      {connectedTasks && connectedTasks.length > 0 && (
        <section className="text-typography-dark mb-4 lg:mb-0">
          <p className="border-t tablet:border-none mt-4 pt-4 tablet:pt-0 tablet:mt-0 text-text-md-semibold">
            { intl.formatMessage(
              {id : 'tickets.ticket.relatedTasks'} , 
              { tasksCount: connectedTasks.length, taskLabel: tasksLabel(connectedTasks.length) },
            )}
          </p>
          {connectedTasks.map((task) => (
            <a
              key={task.id}
              className="underline cursor-pointer text-text-md-medium block hover:text-hover mobile:select-none"
              href={`/zadania/${task.id}`}
              tabIndex={0}
              aria-label={intl.formatMessage(
                { id: 'tasks.task.goToTask' },
                { taskNumberTitle: `${task.taskNumber} ${task.title}` },
              )}
            >
              {`${task.taskNumber} ${task.title}`}
            </a>
          ))}
        </section>
      )}
    </>
  );
};

export default ConnectedTicketSection;
