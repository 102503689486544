import * as React from 'react';
import format from 'date-fns/format';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { SettlementDocument, SettlementTitleBalance } from '../../../../api/modules/settlements/settlementsTypes';
import ResponsiveTable, {
  ResponsiveTableBody,
  ResponsiveTableBodyRow,
  ResponsiveTableHeader,
} from '../../../../components/Table/ResponsiveTable';
import { TableId } from '../../../../store/appSlice';
import { TableBodyCell } from '../../../../components/Table/Table';
import Chip from '../../../../components/Chip/Chip';
import useMessages from '../../../../hooks/useMessages';
import ItemsTable from './ItemsTable';
import TableBodyPlaceholder from '../../../../components/Table/TableBodyPlaceholder';

interface Props {
  data?: SettlementTitleBalance
}

const columnLabels = [
  { label: 'settlements.titleBalance.header.operation' },
  { label: 'settlements.titleBalance.header.creationDate' },
  { label: 'settlements.titleBalance.header.paymentDate' },
  { label: 'settlements.titleBalance.header.status' },
  { label: 'settlements.titleBalance.header.deposits', divClassName: 'justify-end' },
  { label: 'settlements.titleBalance.header.credits', divClassName: 'justify-end' },
  { label: 'settlements.titleBalance.header.balance', divClassName: 'justify-end md:pr-8' },
];

const DesktopTitleBalanceTable = ({ data }: Props) => {
  const getMessage = useMessages();
  const intl = useIntl();
  const {
    documents, openingBalance, sum, title,
  } = data || {};

  const ids = useMemo(() => ['openingBalance'].concat(documents?.map(({ description }) => description) || []).concat(['sum']), [data]);
  const sortedDocuments = useMemo(
    () => {
      const docs: SettlementDocument[] = [];
      documents?.forEach((doc) => docs.push(doc));
      docs.sort((docA, docB) => {
        if (docA.date >= docB.date) {
          return 1;
        }
        return -1;
      });
      return docs;
    },
    [documents],
  );

  return (
    <ResponsiveTable className="col-span-full lg:px-0">
      <ResponsiveTableHeader
        ids={ids}
        columnLabels={columnLabels}
        tableName={'titlesBalanceTable' as TableId}
        firstItemClassName="md:pl-8"
      />
      <ResponsiveTableBody>
        {openingBalance && (
          <ResponsiveTableBodyRow key="openingBalance" className="border-b md:bg-light-200 last:border-b-0 border-separate font-medium md:m-2 rounded">
            <TableBodyCell className="md:pl-8">{getMessage('settlements.balance.openingBalance')}</TableBodyCell>
            <TableBodyCell />
            <TableBodyCell />
            <TableBodyCell />
            <TableBodyCell className="text-right">{openingBalance.debits}</TableBodyCell>
            <TableBodyCell className="text-right">{openingBalance.credits}</TableBodyCell>
            <TableBodyCell className="text-right" cellPadding="md:pr-8">{openingBalance.balance}</TableBodyCell>
          </ResponsiveTableBodyRow>
        )}
        {sortedDocuments.length > 0 ? sortedDocuments.map(({
          operation, date, deadline, open, debits, credits, balance, items, value, vat, attachment, id, readAt,
        }) => (
          <ResponsiveTableBodyRow
            key={operation + date + balance}
            className="border-b last:border-b-0 border-separate font-medium"
            ExpandedChild={items?.length > 0 && (
              <tr>
                <td colSpan={8}>
                  <ItemsTable items={items} value={value} vat={vat} attachment={attachment} readAt={readAt} documentId={id} />
                </td>
              </tr>
            )}
          >
            <TableBodyCell className="md:pl-8">{operation}</TableBodyCell>
            <TableBodyCell>
              <Chip chipStyle="gray" className="w-fit">
                {format(new Date(date), 'dd-MM-yyyy')}
              </Chip>
            </TableBodyCell>
            <TableBodyCell>
              {deadline && (
                <Chip chipStyle="gray" className="w-fit">
                  {format(new Date(deadline), 'dd-MM-yyyy')}
                </Chip>
              )}
            </TableBodyCell>
            <TableBodyCell>
              <div id={`status-checkbox-${id}`} className="hidden">
                {open ? getMessage('settlements.titleBalance.header.status.open') : getMessage('settlements.titleBalance.header.status.notOpened')}
              </div>
              <input
                type="checkbox"
                disabled
                checked={!open}
                aria-labelledby={`status-checkbox-${id}`}
                title={open ? getMessage('settlements.titleBalance.header.status.open') : getMessage('settlements.titleBalance.header.status.notOpened')}
              />
            </TableBodyCell>
            <TableBodyCell className="text-right">{debits}</TableBodyCell>
            <TableBodyCell className="text-right">{credits}</TableBodyCell>
            <TableBodyCell className="text-right" cellPadding="md:pr-8">{balance}</TableBodyCell>
          </ResponsiveTableBodyRow>
        )) : <TableBodyPlaceholder text={intl.formatMessage({ id: 'settlements.titleBalance.noResults' }, { title })} />}
        {sum && (
          <ResponsiveTableBodyRow key="sum" className="border-b md:bg-light-200 last:border-b-0 border-separate font-medium md:m-2 rounded">
            <TableBodyCell className="md:pl-8">{getMessage('settlements.balance.sum')}</TableBodyCell>
            <TableBodyCell />
            <TableBodyCell />
            <TableBodyCell />
            <TableBodyCell className="text-right">{sum.debits}</TableBodyCell>
            <TableBodyCell className="text-right">{sum.credits}</TableBodyCell>
            <TableBodyCell className="text-right" cellPadding="md:pr-8">{sum.balance}</TableBodyCell>
          </ResponsiveTableBodyRow>
        )}
      </ResponsiveTableBody>
    </ResponsiveTable>
  );
};

export default DesktopTitleBalanceTable;
