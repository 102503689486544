import useMessages from "../app/hooks/useMessages";

export const tasksLabel = (nr:number) => {
    const getMessage = useMessages();
  
    if(nr === 1){
      return getMessage('tickets.ticket.relatedTasks.one')
    } else if ((nr % 10 === 2 || nr % 10 === 3 || nr % 10 === 4) && (nr < 10 || nr > 20)) {
      return getMessage('tickets.ticket.relatedTasks.few')
    } else {
      return getMessage('tickets.ticket.relatedTasks.other')
    }
  }