import * as React from 'react';
import { useEffect } from 'react';
import { useGetUsersAccountsQuery } from '../../../api/appApi';
import usePagination from '../../../hooks/usePagination';
import { TableBodyCell } from '../../../components/Table/Table';
import { FilterValues, TableId } from '../../../store/appSlice';
import TableContentPlaceholder from '../../../components/Table/TableContentPlaceholder';
import Chip from '../../../components/Chip/Chip';
import PaginationControls from '../../../components/Table/PaginationControls';
import ResponsiveTable, {
  ResponsiveTableBody,
  ResponsiveTableBodyRow,
  ResponsiveTableHeader,
} from '../../../components/Table/ResponsiveTable';
import useFilters from '../../../hooks/useFilters';
import useSortParams from '../../../hooks/useSortParams';


interface Props {
  prefilter: 'all' | 'workers',
}

const UsersList = ({ prefilter }: Props) => {
  const [selectedPage, setSelectedPage] = usePagination('usersTable');
  const { filterValues } = useFilters('usersTable');
  const { sortParamsValues } = useSortParams('usersTable');
  const filters: FilterValues = { ...filterValues };
  if (prefilter === 'workers') filters.role_type = 'worker';

  useEffect(() => {
    setSelectedPage(1);
  }, [prefilter]);

  const { data } = useGetUsersAccountsQuery({
    ...filters,
    ...sortParamsValues,
    page: selectedPage.toString(),
  }, { refetchOnMountOrArgChange: true });

  const ids = data?.accounts.map(({ id }) => id) || [];
  const columnLabels = [
    { label: 'users.column.id', id: 'external_id' },
    { label: 'users.column.name', id: 'full_name' },
    { label: 'users.column.login', id: 'login' },
    { label: 'users.column.roles' },
    { label: 'users.column.email', id: 'email' },
  ];

  return (
    <>
      <ResponsiveTable className="col-span-full">
        {data?.accounts.length === 0
          ? (
            <TableContentPlaceholder />
          ) : (
            <>
              <ResponsiveTableHeader ids={ids} columnLabels={columnLabels} tableName={'usersTable' as TableId} />
              <ResponsiveTableBody>
                {(data?.accounts || []).map(({
                  externalId, fullName, login, roles, email, id,
                }) => (
                  <ResponsiveTableBodyRow
                    key={id}
                    className="border-b last:border-b-0"
                    clickable={({
                      href: `/uzytkownicy/${id}`,
                      ariaLabel: `Przejdź do szczegółow na temat ${fullName} ${externalId || ''}`,
                    })}
                  >
                    <TableBodyCell>{externalId}</TableBodyCell>
                    <TableBodyCell>{fullName}</TableBodyCell>
                    <TableBodyCell>{login}</TableBodyCell>
                    <TableBodyCell>
                      {roles.map((role) => (
                        <Chip className="w-fit" chipStyle="gray" key={role}>
                          {role}
                        </Chip>
                      ))}
                    </TableBodyCell>
                    <TableBodyCell>{email}</TableBodyCell>
                  </ResponsiveTableBodyRow>
                ))}
              </ResponsiveTableBody>
            </>
          )}

      </ResponsiveTable>
      {data?.metadata && (
        <PaginationControls
          className="col-span-full"
          selectedPage={selectedPage}
          setSelectedPage={setSelectedPage}
          paginationMetadata={data.metadata}
        />
      )}
    </>
  );
};

export default UsersList;
