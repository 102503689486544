/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */
import * as React from 'react';
import { Link } from 'react-router-dom';
import format from 'date-fns/format';
import { useGetFullTaskStatusesQuery, useGetTasksQuery } from '../../../api/appApi';
import Chip from '../../../components/Chip/Chip';
import TableContentPlaceholder from '../../../components/Table/TableContentPlaceholder';
import HourglassIcon from '../../../icons/hourglass.svg';
import useMessages from '../../../hooks/useMessages';
import capitalize from '../../../../utils/formatters';
import SpinnerItem from '../../../components/Spinner/SpinnerItem';

const TasksWidget = () => {
  const getMessage = useMessages();
  const { data: allStatuses, isLoading: isStatusesLoading } = useGetFullTaskStatusesQuery();

  // only fetch tasks with statuses other than open and completed
  const statusesIds = (allStatuses
    ?.filter((statusItem) => statusItem.name !== 'open' && statusItem.name !== 'completed') || [])
    .map((item) => item.id);
  const { data, isLoading, isSuccess } = useGetTasksQuery({
    status_ids: statusesIds,
    assigned: 'me'
  }, { skip: !allStatuses });

  const tasks = data ? data.tasks.slice(0, 3) : [];

  return (
    <div className="col-start-1 col-span-full md:col-start-1 md:col-span-1 lg:col-start-9 lg:col-span-4 mb-6 tablet:mb-0 ">
      <div className="col-span-12">
        <p className="text-header-xs mb-2 inline-block">{getMessage('dashboard.widget.tasks.inProgress', {number: data?.metadata?.count})}</p>
      </div>
      <div className="col-span-12 w-full">
        {(isStatusesLoading || isLoading) && [...Array(3)].map((_, index) => (
          <SpinnerItem
            key={index}
            containerStyles="h-[108px] md:h-[110px] list-none p-5 mb-1 last:mb-0 bg-background-white border rounded border-stroke-grey-100 flex items-center"
            spinnerStyles="m-auto"
          />
        ))}
        {isSuccess && (tasks.length === 0
          ? <TableContentPlaceholder />
          : tasks.map(({
            id, title, status, venueAddress, estimatedFinishTime, taskNumber,
          }) => (
            <li
              className="list-none p-5 bg-background-white border rounded border-stroke-grey-100 mb-1 last:mb-0
                truncate flex hover:border-hover hover:cursor-pointer focus-within:border-hover relative"
              key={id}
            >
              <div className="bg-light-200 fill-bg-light-200 text-main p-4 rounded h-fit flex items-center mr-5">
                <HourglassIcon />
              </div>
              <div className="w-full truncate">
                <p className="text-typography-black truncate text-title-semibold">
                  {title}
                </p>
                <p className="text-xs">{venueAddress}</p>
                <div className="flex flex-wrap justify-between items-center mt-1">
                  {status && (
                    <Chip chipStyle={status === 'zakończone' ? 'gray' : 'primary'}>
                      {capitalize(status)}
                    </Chip>
                  )}
                  {estimatedFinishTime && (
                    <Chip chipStyle="ghost" className="mobile:mt-1">
                      {`do ${format(new Date(estimatedFinishTime), 'dd-MM-yyyy')}`}
                    </Chip>
                  )}
                </div>
              </div>
              <Link
                to={`/zadania/${id}`}
                state={{ returnTo: '/' }}
                aria-label={`Przejdź do szczegółów zadania nr ${taskNumber} ${title}`}
                className='after:absolute after:content-[""] after:h-full after:w-full after:left-0 after:top-0'
              />
            </li>
          )))}
      </div>
    </div>
  );
};

export default TasksWidget;
