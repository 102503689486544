import * as React from 'react';
import { useMemo } from 'react';
import {
  useGetSelectedTasksColumnsQuery,
  usePostSelectedTasksColumnsMutation,
} from '../../../api/appApi';
import { SelectOption } from '../../../components/FormInputs/inputs/FormSelect';
import useMessages from '../../../hooks/useMessages';
import TableCustomisationMenu from '../../../components/TableCustomisationMenu/TableCustomisationMenu';
import { taskCustomizableColumnIds, taskDefaultColumnIds } from '../../../api/modules/tasks/tasksTypes';
import useHomeownerAssociationsMode from '../../../hooks/useHomeownerAssociationsMode';

const TasksTableCustomisation = () => {
  const getMessage = useMessages();
  const hoaMode = useHomeownerAssociationsMode();
  const { data: selectedColumns } = useGetSelectedTasksColumnsQuery();
  const [postSelectedTaskColumns] = usePostSelectedTasksColumnsMutation();

  const selectOptions: SelectOption[] = useMemo(() => {
    const customizableColumns = taskCustomizableColumnIds.map((id) => ({
      value: id,
      optionLabel: getMessage(`tasks.columnConfig.${id}`),
    }));

    return hoaMode ? customizableColumns : customizableColumns.filter((option) => option.value !== 'homeowner_association_name');
  }, [hoaMode]);

  if (!selectedColumns || !postSelectedTaskColumns) return null;

  const handleSelectedColumns = (selectedColumns: string[]) => {
    postSelectedTaskColumns({ columns: selectedColumns });
  };

  return (
    <TableCustomisationMenu
      selectedColumns={selectedColumns}
      setSelectedColumns={handleSelectedColumns}
      selectOptions={selectOptions}
      tableId="TasksTable"
    />
  );
};

export default TasksTableCustomisation;
