import { useIntl } from 'react-intl';
import { useCallback, useMemo } from 'react';
import { Permission, PermissionCategory } from '../../utils/generated/permissionsTypes';

const useMessages = () => {
  const intl = useIntl();
  return (id: string, values?: object) => intl.formatMessage({ id }, {...values});
};

export default useMessages;

export const usePermissionMessages = () => {
  const intl = useIntl();

  // eslint-disable-next-line arrow-body-style
  const getPermissionMessage = useCallback((category: PermissionCategory, permission: Permission | null): string => {
    return intl.formatMessage({ id: `permissions.${category}${permission ? `.${permission}` : ''}` });
  }, []);

  return getPermissionMessage;
};

export const useMonthsAndWeekDays = () => {
  const getMessage = useMessages();
  const months = useMemo(() => [
    getMessage('datepicker.month.january'),
    getMessage('datepicker.month.february'),
    getMessage('datepicker.month.march'),
    getMessage('datepicker.month.april'),
    getMessage('datepicker.month.may'),
    getMessage('datepicker.month.june'),
    getMessage('datepicker.month.july'),
    getMessage('datepicker.month.august'),
    getMessage('datepicker.month.september'),
    getMessage('datepicker.month.october'),
    getMessage('datepicker.month.november'),
    getMessage('datepicker.month.december'),
  ], []);
  const weekDays = useMemo(() => [
    getMessage('datepicker.week.sunday'),
    getMessage('datepicker.week.monday'),
    getMessage('datepicker.week.tuesday'),
    getMessage('datepicker.week.wednesday'),
    getMessage('datepicker.week.thursday'),
    getMessage('datepicker.week.friday'),
    getMessage('datepicker.week.saturday'),
  ], []);

  return { months, weekDays };
};
