import * as React from 'react';
import { useParams } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import useMessages from '../../../hooks/useMessages';
import PageHeader from '../../../components/Layout/PageHeader';
import ContentWrapper from '../../../components/ContentWrapper/ContentWrapper';
import Layout from '../../../components/Layout/Layout';
import { useGetTaskQuery } from '../../../api/appApi';
import MainTaskCard from './MainTaskCard';
import TaskDesktopInfoCard from './TaskInfoCard/TaskDesktopInfoCard';
import TaskMobileInfoCard from './TaskInfoCard/TaskMobileInfoCard';
import { usePermissions } from '../../../hooks/usePermissions';
import useDocumentTitle from '../../../hooks/useDocumentTitle';

const TaskPage = () => {
  const { id } = useParams();
  const { checkModuleEnabled } = usePermissions();
  const getMessage = useMessages();
  const { data: task } = useGetTaskQuery(id || '', { skip: !id });

  useDocumentTitle(getMessage('tasks.pageTitle', {number: task?.taskNumber}));


  checkModuleEnabled('tickets');
  if (!id) return null;

  return (
    <Layout currentPage="tasks">
      <PageHeader pageTitle={`${getMessage('tasks.task')} ${task ? task.taskNumber : ''}`} currentPage="tasks" path="/zadania/otwarte" />
      {task && <TaskMobileInfoCard task={task} title={getMessage('meters.metersList.info')} />}
      <ContentWrapper>
        {task && (
          <>
            <MainTaskCard task={task} />
            <TaskDesktopInfoCard task={task} />
          </>
        )}
      </ContentWrapper>
    </Layout>
  );
};

export default TaskPage;
