import {
  months,
  ApiSettlementResponse,
  ApiMonthBalance,
  YearsBalance,
  MonthBalance,
  ApiYearsResponse,
  ApiSettlementTitlesResponse,
  SettlementTitle,
  ApiMonthsSettlementsResponse,
  MonthsTitle,
  ApiDocumentsResponse,
  SettlementTitleBalance,
  SettlementDocument,
  ApiDocumentItem,
  DocumentItem,
  ApiDocumentWithItems,
  ApiPayment,
  Payment,
  ApiPaymentsResponse,
  ApiDocumentAttachment,
  DocumentAttachment,
  ApiBMPaymentResponse,
  BMPayment,
  ApiSettlementVenuesResponse,
  SettlementsVenues,
  ApiHistoryPayment,
  HistoryPayment,
  ApiPaymentsHistoryResponse,
  PaymentsHistory,
  PaymentsBalances,
} from './settlementsTypes';
import { formatBankAccount, formatNumber } from '../../../../utils/formatters';

export const translateBMPayment = (apiResponse: ApiBMPaymentResponse): BMPayment => ({
  id: apiResponse.payment.id,
  amount: apiResponse.payment.amount,
  bankAccount: apiResponse.payment.bank_account,
  description: apiResponse.payment.description,
  orderId: apiResponse.payment.order_id,
  paymentDate: apiResponse.payment.payment_date,
  redirectUrl: apiResponse.payment.redirect_url,
  remoteId: apiResponse.payment.remote_id,
  status: apiResponse.payment.status,
});

const translatePayment = (apiPayment: ApiPayment): Payment => ({
  value: apiPayment.value,
  titleNames: apiPayment.title_names,
  accountNumber: apiPayment.account_number,
  bmPayment: apiPayment.bm_payment ? translateBMPayment({ payment: apiPayment.bm_payment }) : undefined,
});

export const translateApiPayments = (apiPaymentsResponse: ApiPaymentsResponse): Payment[] => apiPaymentsResponse.payments.map(
  (apiPayment) => translatePayment(apiPayment),
);

const translateApiMonth = (apiMonth: ApiMonthBalance, monthNr: number): MonthBalance => ({
  month: months[monthNr - 1],
  balance: formatNumber(apiMonth.balance || '0.00'),
  debits: formatNumber(apiMonth.debits || '0.00'),
  credits: formatNumber(apiMonth.credits || '0.00'),
});

export const translateYearsBalance = (apiResponse: ApiSettlementResponse): YearsBalance => {
  const months = [];
  for (let i = 1; i <= 12; i += 1) {
    if (apiResponse.months[i]) {
      months.push(translateApiMonth(apiResponse.months[i], i));
    }
  }
  const openingBalance = {
    balance: formatNumber(apiResponse.months['0'].balance || '0.00'),
    debits: formatNumber(apiResponse.months['0'].debits || '0.00'),
    credits: formatNumber(apiResponse.months['0'].credits || '0.00'),
  };
  const sum = {
    debits: formatNumber(apiResponse.year_balance.debits || '0.00'),
    credits: formatNumber(apiResponse.year_balance.credits || '0.00'),
    balance: formatNumber(apiResponse.year_balance.balance || '0.00'),
  };

  return { openingBalance, months, sum };
};

export const translateYears = (apiYears: ApiYearsResponse): string[] => (apiYears?.years || []).map((year) => year.toString());

export const translateSettlementTitles = (apiTitles: ApiSettlementTitlesResponse): SettlementTitle[] => apiTitles.titles.map((apiTitle) => ({
  id: apiTitle.id,
  name: apiTitle.name,
  bankAccountNumber: apiTitle.bank_account_number,
}));

export const translateMonthsTitles = (apiResponse: ApiMonthsSettlementsResponse): MonthsTitle[] => Object.keys(apiResponse.titles).map((key) => ({
  id: key,
  name: apiResponse.titles[key].name,
  balance: formatNumber(apiResponse.titles[key].balance),
  bankAccountNumber: apiResponse.titles[key].bank_account_number ? formatBankAccount(apiResponse.titles[key].bank_account_number) : '',
}));

export const translateAttachment = (apiAttachment: ApiDocumentAttachment): DocumentAttachment => ({
  path: apiAttachment.path,
  contentType: apiAttachment.content_type,
  filename: apiAttachment.filename,
});

export const translateDocumentItem = (apiDocumentItem: ApiDocumentItem): DocumentItem => {
  const {
    item_name: itemName, amount, price, value, ...rest
  } = apiDocumentItem;
  return {
    itemName,
    amount: formatNumber(amount),
    price: formatNumber(price),
    value: formatNumber(value),
    ...rest,
  };
};

export const translateDocument = (apiDocument: ApiDocumentWithItems): SettlementDocument => {
  const {
    items, attachment, read_at: readAt, debits, credits, balance, vat, value, ...rest
  } = apiDocument;
  return {
    items: (items || []).map((apiItem) => translateDocumentItem(apiItem)),
    attachment: attachment ? translateAttachment(attachment) : undefined,
    readAt: readAt || undefined,
    debits: formatNumber(debits),
    credits: formatNumber(credits),
    balance: formatNumber(balance),
    vat: formatNumber(vat),
    value: formatNumber(value),
    ...rest,
  };
};

export const translateTitleDocuments = (apiResponse: ApiDocumentsResponse): SettlementTitleBalance => ({
  title: apiResponse.entry_month_balance.title,
  openingBalance: {
    balance: formatNumber(apiResponse.entry_month_balance.balance),
    debits: formatNumber(apiResponse.entry_month_balance.debits),
    credits: formatNumber(apiResponse.entry_month_balance.credits),
  },
  sum: {
    balance: formatNumber(apiResponse.month_balance.balance),
    debits: formatNumber(apiResponse.month_balance.debits),
    credits: formatNumber(apiResponse.month_balance.credits),
  },
  documents: apiResponse.documents.map((apiDocument: ApiDocumentWithItems) => translateDocument(apiDocument)),
});

export const translateVenues = (apiResponse: ApiSettlementVenuesResponse): SettlementsVenues => ({
  metadata: apiResponse.metadata,
  venues: apiResponse.venues.map((apiVenue) => ({
    venueId: apiVenue.venue_id,
    tenantId: apiVenue.tenant_id,
    address: apiVenue.address,
    building: apiVenue.building,
    city: apiVenue.city,
    code: apiVenue.code,
    homeownerAssociation: apiVenue.homeowner_association,
    membership: apiVenue.membership,
    residencyFrom: apiVenue.residency_from,
    residencyUntil: apiVenue.residency_until,
    tenant: apiVenue.tenant,
    tenantExternalId: apiVenue.tenant_external_id,
  })),
});

export const translateHistoryPayment = (apiPayment: ApiHistoryPayment): HistoryPayment => ({
  status: apiPayment.status,
  description: apiPayment.description,
  bankAccount: apiPayment.bank_account,
  amount: apiPayment.amount,
  paymentDate: apiPayment.payment_date,
});

export const translatePaymentHistory = (apiHistory: ApiPaymentsHistoryResponse): PaymentsHistory => ({
  metadata: apiHistory.metadata,
  payments: apiHistory.payments.map((apiPayment) => translateHistoryPayment(apiPayment)),
});

export const translateVenuesPaymentsBalances = (apiVenuesPaymentsBalances: PaymentsBalances) => (
  Object.keys(apiVenuesPaymentsBalances).reduce((acc, currentVenueId) => ({
    ...acc,
    [currentVenueId]: {
      balance: formatNumber(apiVenuesPaymentsBalances[currentVenueId].balance || '0.00'),
      liability: formatNumber(apiVenuesPaymentsBalances[currentVenueId].liability || '0.00'),
      overpayment: formatNumber(apiVenuesPaymentsBalances[currentVenueId].overpayment || '0.00'),
    },
  }), {}));
