/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */
import * as React from 'react';
import format from 'date-fns/format';
import { useNavigate, Link } from 'react-router-dom';
import classNames from 'classnames';
import { useGetAnnouncementsQuery } from '../../../api/appApi';
import Chip from '../../../components/Chip/Chip';
import TableContentPlaceholder from '../../../components/Table/TableContentPlaceholder';
import TypeBadge from '../../../components/TypeBadge/TypeBadge';
import Card from '../../../components/Card/Card';
import AnnouncementIcon from '../../../icons/announcements.svg';
import useMessages from '../../../hooks/useMessages';
import SpinnerItem from '../../../components/Spinner/SpinnerItem';
import { usePermissions } from '../../../hooks/usePermissions';

const AnnouncementsWidget = () => {
  const { data, isLoading } = useGetAnnouncementsQuery({ archived: false });
  const getMessage = useMessages();
  const navigate = useNavigate();
  const { hasRoleType } = usePermissions();

  const announcements = data ? data.announcements.slice(0, 4) : [];

  return (
    <div className="col-span-full lg:col-span-8">
      <div className="col-span-12 flex justify-between">
        <p className="text-header-xs mb-2">{getMessage('dashboard.widget.announcements', {number: data?.metadata?.count})}</p>
        {hasRoleType('worker') && (
          <button onClick={() => navigate('/aktualnosci/nowe', { state: { returnTo: '/' } })} type="button" className="text-main bg-background-white rounded-sm text-xs font-bold hover:text-hover hover:underline px-4 mb-1">
            {getMessage('dashboard.widget.announcements.addAnnouncement')}
          </button>
        )}
      </div>
      <Card className={`${announcements.length === 4 ? 'h-[509px] md:h-[365px]' : 'auto'}  md:!p-6`}>
        {isLoading && [...Array(4)].map((_, index) => (
          <SpinnerItem
            key={index}
            containerStyles="first:h-[109px] last:h-[109px] h-[125px] md:h-auto md:last:h-auto md:first:h-auto w-full border-b last:border-b-0 py-4 first:pt-0 last:pb-0 flex gap-4"
          />
        ))}
        {announcements.length === 0
          ? <TableContentPlaceholder />
          : announcements.map(({
            id, createdAt, title, description, status, announcementNumber,
          }) => (
            <li
              className="w-full border-b last:border-b-0 py-4 first:pt-0 last:pb-0 flex gap-4
              hover:cursor-pointer hover:bg-gray-50 shadow-gray
              focus-within:border focus-within:border-hover relative"
              key={id}
            >
              <TypeBadge className="flex-none" badgeStyle={status === 'unread' ? 'red' : 'grey'}>
                <AnnouncementIcon />
              </TypeBadge>
              <div className="flex-grow md:flex md:justify-between md:gap-4 truncate">
                <div className="flex flex-col md:flex-grow truncate">
                  <p className={classNames(
                    'text-typography-black mb-2 truncate',
                    status === 'unread' ? 'text-title-semibold' : 'text-title-medium',
                  )}
                  >
                    {title}
                  </p>
                  <p className="text-text-md-medium text-typography-dark truncate">{description}</p>
                </div>
                <Chip chipStyle="gray" className="mobile:mt-4 md:flex-none w-fit">
                  {format(new Date(createdAt), 'dd-MM-yyyy')}
                </Chip>
              </div>
              <Link
                to={`/aktualnosci/${id}`}
                state={{ returnTo: '/' }}
                aria-label={`Przejdź do szczegółów aktualności nr ${announcementNumber} ${title}`}
                className='after:absolute after:content-[""] after:h-full after:w-full after:left-0 after:top-0'
              />
            </li>
          ))}
      </Card>
    </div>
  );
};

export default AnnouncementsWidget;
