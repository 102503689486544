import * as React from 'react';
import {
  useCallback, useMemo, useState, useEffect,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import useMessages from '../../../hooks/useMessages';
import {
  useGetDocumentQuery, useGetDocumentsCategoriesQuery,
  useGetDocumentStatusesQuery,
  useGetEmployeesQuery,
  usePostChangeDocumentMutation, usePostRevokeDocumentMutation,
} from '../../../api/appApi';
import capitalize from '../../../../utils/formatters';
import { InputLabel } from '../../../components/FormInputs/InputLabel';
import { Select } from '../../../components/FormInputs/inputs/FormSelect';
import SearchableDropdown from '../../../components/SearchableDropdown/SearchableDropdown';
import { FullDocument } from '../../../api/modules/documents/documentsTypes';
import Button from '../../../components/Button/Button';
import Dialog from '../../../components/Dialog/Dialog';
import { FormNotice } from '../../../components/FormNotice/FormNotice';
import { tasksLabel } from '../../../../utils/common';

const DocumentInfoCard = () => {
  const [realizerState, setRealizerState] = useState<string | null>(null);
  const getMessage = useMessages();
  const intl = useIntl();
  const navigate = useNavigate();
  const { id } = useParams();

  const { data: documentStatuses } = useGetDocumentStatusesQuery();
  const { data: documentCategories } = useGetDocumentsCategoriesQuery();
  const { data: document, isSuccess } = useGetDocumentQuery(id);
  const [postChange, changeDocumentResult] = usePostChangeDocumentMutation();
  const [revokeDocument, revokeDocumentResult] = usePostRevokeDocumentMutation();
  const [revokePopupOpen, setRevokePopupOpen] = useState(false);

  const {
    status, realizer, category, creator, sender, receiver, tenant, tasks, revoked,
  } = isSuccess ? document : {} as FullDocument;

  useEffect(() => {
    if (realizer) setRealizerState(realizer.name);
  }, [realizer]);

  const statusOptionsValues = useMemo(() => documentStatuses?.map(({ id, name }) => ({
    value: id,
    optionLabel: capitalize(name),
  })) || [], [documentStatuses]);

  const categoriesOptionsValues = useMemo(() => documentCategories?.map(({ id, name }) => ({
    value: id,
    optionLabel: capitalize(name),
  })) || [], [documentCategories]);

  const changeParam = useCallback((paramName: string, paramValue: string | null): void => {
    if (id) {
      postChange({
        documents: {
          [id]: {
            [paramName]: paramValue,
          },
        },
      });
    }
  }, [id]);

  if (!id) return null;

  return (
    <div className="w-full flex flex-col text-text-md-semibold">
      <Dialog
        isOpen={revokePopupOpen}
        onAccept={() => revokeDocument(id)}
        onClose={() => setRevokePopupOpen(false)}
      >
        <h3 className="text-xl font-semibold">
          { intl.formatMessage({ id: 'documents.document.revokePopup.title' }, { documentNumber: document?.documentNumber }) }
        </h3>
        <p className="mb-12 mt-8 mx-16 text-sm text-interactions-error-text">
          { getMessage('documents.document.revokePopup.description') }
        </p>
      </Dialog>
      <section className="border-b pb-4 tablet:flex tablet:w-full">
        <div className="tablet:mr-5 tablet:w-1/4">
          {documentStatuses && (
            <>
              <InputLabel
                label={getMessage('documents.document.status')}
              />
              <Select
                selectOptions={statusOptionsValues}
                onChange={(id) => changeParam('status_id', id)}
                value={status.id}
              />
            </>
          )}
        </div>
        <div className="tablet:mr-5 tablet:w-1/4">
          {documentCategories && (
          <>
            <InputLabel
              label={getMessage('documents.document.category')}
            />
            <Select
              selectOptions={categoriesOptionsValues}
              onChange={(id) => changeParam('category_id', id)}
              value={category.id}
            />
          </>
          )}
        </div>
        <div className="tablet:mr-5 tablet:w-1/4">
          <InputLabel
            label={getMessage('documents.document.realizer')}
            showReset={!!realizerState}
            resetButtonText={getMessage('remove')}
            onReset={() => {
              changeParam('realizer_id', null);
              setRealizerState(null);
            }}
          />
          <SearchableDropdown
            className="w-full"
            query={useGetEmployeesQuery}
            onSelect={(id) => changeParam('realizer_id', id)}
            initialState={realizerState}
            ariaLabel={getMessage('form.ariaLabel.realizer')}
          />
        </div>
        {changeDocumentResult.error && (
          /* @ts-ignore */
          <FormNotice noticeClassName="col-span-full tablet:mr-5 mt-3" type="error" message={changeDocumentResult.error.data?.errors || getMessage('form.error')} />
        )}
        {!revoked && (
          <Button
            size="small"
            className="min-w-full tablet:min-w-fit lg:w-auto mt-4"
            onClick={() => setRevokePopupOpen(true)}
          >
            {getMessage('documents.document.revoke')}
          </Button>
        )}
        {revokeDocumentResult.error && (
          /* @ts-ignore */
          <FormNotice noticeClassName="col-span-full tablet:mr-5 mt-3" type="error" message={revokeDocumentResult.error.data?.errors || getMessage('documents.document.revoked.error')} />
        )}
      </section>
      <section className="w-full border-b py-4">
        <p className="text-text-md-semibold text-typography-dark">
          {getMessage('documents.document.creator')}
          <span className="text-text-md-medium ml-2">
            {creator.name}
          </span>
        </p>
        {sender && (
          <p className="text-text-md-semibold text-typography-dark">
            {getMessage('documents.document.sender')}
            <span className="text-text-md-medium ml-2">
              {sender}
            </span>
          </p>
        )}
        {receiver && (
          <p className="text-text-md-semibold text-typography-dark">
            {getMessage('documents.document.receiver')}
            <span className="text-text-md-medium ml-2">
              {receiver}
            </span>
          </p>
        )}
        {tenant && (
          <p className="text-text-md-semibold text-typography-dark">
            {getMessage('documents.document.tenant')}
            <span className="text-text-md-medium ml-2">
              {tenant.name}
            </span>
          </p>
        )}
      </section>
      <section className=" w-full tablet:flex tablet:justify-between tablet:items-end mb-4 lg:mb-0 pt-4">
        {tasks && tasks.length > 0 && (
          <div>
            <p className="text-text-md-semibold text-typography-dark ">
              {intl.formatMessage(
                { id: 'documents.document.relatedTasks' },
                { tasksCount: tasks.length, taskLabel: tasksLabel(tasks.length) },
              )}
            </p>
            {tasks.map((task) => (
              <a
                key={task.id}
                className="underline cursor-pointer text-typography-dark block hover:text-main mobile:select-none"
                href={`/zadania/${task.id}`}
                tabIndex={0}
                aria-label={intl.formatMessage(
                  { id: 'tasks.task.goToTask' },
                  { taskNumberTitle: `${task.taskNumber} ${task.title}` },
                )}
              >
                {`Nr ${task.taskNumber} ${task.title}`}
              </a>
            ))}
          </div>
        )}
        <Button
          className="min-w-full tablet:min-w-fit lg:w-auto mt-4"
          onClick={() => navigate(`/dokumenty/nowe-zadanie/${id}`)}
        >
          {getMessage('documents.document.addTask')}
        </Button>
      </section>
    </div>
  );
};

export default DocumentInfoCard;
