import * as React from 'react';
import { useIntl } from 'react-intl';
import {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { Link } from 'react-router-dom';
import { usePermissions } from '../../hooks/usePermissions';
import ArrowUp from '../../icons/dropdown-arrow-up.svg';
import ArrowDown from '../../icons/dropdown-arrow-down.svg';
import UserNavigation from './UserNavigation/UserNavigation';
import MainNavigation from './MainNavigation/MainNavigation';
import { useGetCompanyDataQuery, useGetFullUserAccountQuery, useLogoutMutation } from '../../api/appApi';
import { useAppSelector } from '../../hooks/reduxHooks';
import useClickOutside from '../../hooks/useClickOutside';
import { NavigationRoutes } from '../Layout/Layout';
import Spinner from '../Spinner/Spinner';
import useMessages from '../../hooks/useMessages';
import LogoutIcon from '../../icons/logout.svg';
import VersionDisplay from './VersionDisplay';

interface Props {
  currentPage?: NavigationRoutes,
  closeNavigation?: () => void;
}

const Navigation = ({ currentPage, closeNavigation }: Props) => {
  const intl = useIntl();
  const { hasRoleType } = usePermissions();
  const { data: companyDetails, isSuccess: companyFetched } = useGetCompanyDataQuery();
  const { data: user, isSuccess: userFetched } = useGetFullUserAccountQuery();
  const selectedVenueName = useAppSelector((state) => state.app.selectedVenueName);

  const [userNavigationOpen, setUserNavigationOpen] = useState(false);
  const userNavigationRef = useRef(null);
  const userVenueRef = useRef(null);

  const hasVenues = useMemo(() => !!user?.tenant?.venues && user.tenant.venues.length > 0, [user]);

  const getMessage = useMessages();
  const [logout, logoutResult] = useLogoutMutation();

  useEffect(() => {
    if (logoutResult.status === 'fulfilled') {
      window.location.href = '/accounts/sign_in';
    }
  }, [logoutResult]);

  const handleKeyLogout = useCallback((e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter' || e.key === ' ') {
      logout(null);
    }
  }, []);

  const toggleOpen = () => {
    setUserNavigationOpen((open) => !open);
  };

  const close = () => {
    if (closeNavigation) {
      closeNavigation();
    }
    setUserNavigationOpen(false);
  };

  useClickOutside(userNavigationRef, () => setUserNavigationOpen(false), [userVenueRef]);

  const handleKeyUp = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' || e.key === ' ') {
      toggleOpen();
    }
  };

  return (
    userFetched ? (
      <>
        <a className="absolute -top-20 p-2 text-typography-black text-text-lg-semibold focus:top-0 focus:block focus:bg-background-light" href="#main">Przejdź do treści</a>
        <Link className="hidden lg:flex items-center justify-center h-24 border-b mx-2 sm:mx-3 md:mx-7" to="/" aria-label="Przejdź do strony głównej">
          {companyFetched && companyDetails.logo && (
            <img
              src={companyDetails.logo?.path}
              alt={companyDetails.name || intl.formatMessage({ id: 'navigation.header' })}
              className="w-14 mr-4"
            />
          )}
          <p className="text-typography-black text-text-lg-semibold">{companyFetched && companyDetails.name ? companyDetails.name : intl.formatMessage({ id: 'navigation.header' })}</p>
        </Link>
        <div className="flex flex-col items-start h-20 justify-center mx-2 sm:mx-3 md:mx-7">
          {hasVenues && (
          <div className="text-typography-dark text-text-md-medium font-light">
            {user.fullName}
          </div>
          )}
          {hasRoleType('tenant') && (<span
            role="button"
            onKeyUp={(e) => handleKeyUp(e)}
            onClick={toggleOpen}
            tabIndex={0}
            className="inline-block cursor-pointer w-full text-typography-black text-text-lg-semibold hover:text-hover"
            ref={userVenueRef}
          >
            {hasVenues ? selectedVenueName : user.fullName}
            {userNavigationOpen ? <ArrowUp className="inline float-right ml-1 mt-2 hover:text-hover" /> : <ArrowDown className="inline float-right ml-1 mt-2 hover:text-hover" />}
          </span>)}

          {!hasRoleType('tenant') && (<span className="inline-block w-full text-typography-black text-text-lg-semibold">{user.fullName}</span>)}
        </div>
        <div className="relative">
          <UserNavigation ref={userNavigationRef} isOpen={userNavigationOpen} onSelect={close} />
        </div>
        <MainNavigation currentPage={currentPage} />
        <div className="mx-2 lg:mx-7 border-t">
          <div
            className="ml-3 lg:ml-0 justify-around items-center cursor-pointer w-full block text-typography-dark text-text-lg-medium"
            role="button"
            tabIndex={0}
            onClick={() => logout(null)}
            onKeyUp={(e) => handleKeyLogout(e)}
          >
            <span className="py-6 flex group hover:underline hover:text-main">
              <LogoutIcon className="w-6 group-hover:text-red-main stroke-current" />
              <p className="ml-2 tracking-wide truncate group-hover:text-main">{getMessage('logout')}</p>
            </span>
          </div>
        </div>
        <VersionDisplay />
      </>
    ) : <Spinner className="m-auto mt-6" />
  );
};

export default Navigation;
