import * as React from 'react';
import {
  useMemo, useCallback, useState, useEffect,
} from 'react';
import { useNavigate } from 'react-router';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import useMessages from '../../../../../hooks/useMessages';
import { InputLabel } from '../../../../../components/FormInputs/InputLabel';
import {
  useGetEmployeesQuery,
  usePostChangeTicketMutation,
  useGetTicketCategoriesQuery,
  useGetTicketQuery,
  useGetTicketStatusesQuery,
} from '../../../../../api/appApi';
import capitalize from '../../../../../../utils/formatters';
import { Select } from '../../../../../components/FormInputs/inputs/FormSelect';
import Button from '../../../../../components/Button/Button';
import { EmployeeShowTicketData } from '../../../../../api/modules/tickets/ticketsTypes';
import SearchableDropdown from '../../../../../components/SearchableDropdown/SearchableDropdown';
import useHomeownerAssociationsMode from '../../../../../hooks/useHomeownerAssociationsMode';
import { FormNotice } from '../../../../../components/FormNotice/FormNotice';
import { tasksLabel } from '../../../../../../utils/common';

const TicketInfoCard = () => {
  const [realizerState, setRealizerState] = useState<string | null>(null);
  const hoa = useHomeownerAssociationsMode();

  const getMessage = useMessages();
  const navigate = useNavigate();
  const intl = useIntl();
  const { data: ticketCategories } = useGetTicketCategoriesQuery();
  const { data: ticketStatuses } = useGetTicketStatusesQuery();
  const [postChange, result] = usePostChangeTicketMutation();
  const { id } = useParams();
  const { data, isSuccess } = useGetTicketQuery(id);

  const {
    status, category, reporter, tasks, realizer, tenantName, venueAddress, homeownerAssociationName,
  } = isSuccess ? data as EmployeeShowTicketData : {} as EmployeeShowTicketData;

  useEffect(() => {
    if (realizer) setRealizerState(realizer);
  }, [realizer]);

  const statusOptionsValues = useMemo(() => ticketStatuses?.map(({ id, name }) => ({
    value: id,
    optionLabel: capitalize(name),
  })) || [], [ticketStatuses]);

  const categoryOptionsValues = useMemo(() => ticketCategories?.map(({ id, name }) => ({
    value: id,
    optionLabel: capitalize(name),
  })), [ticketCategories]);

  const changeParam = useCallback((paramName: string, paramValue: string): void => {
    if (id) {
      postChange({
        tickets: {
          [id]: {
            [paramName]: paramValue,
          },
        },
      });
    }
  }, [id]);

  return (
    <div className="w-full flex flex-col text-text-md-semibold">
      <section className="border-b pb-4 tablet:flex tablet:w-full">
        <div className="tablet:mr-5 tablet:w-1/4">
          {ticketStatuses && (
          <>
            <InputLabel
              label={getMessage('tickets.ticket.status')}
            />
            <Select
              selectOptions={statusOptionsValues}
              onChange={(id) => changeParam('status_id', id)}
              value={status.id}
            />
          </>
          )}
        </div>
        <div className="tablet:mr-5 tablet:w-1/4">
          <InputLabel
            label={getMessage('tickets.ticket.category')}
          />
          {categoryOptionsValues && (
          <Select
            selectOptions={categoryOptionsValues}
            onChange={(id) => changeParam('category_id', id)}
            value={category.id}
          />
          )}
        </div>
        <div className="tablet:mr-5 tablet:w-1/4">
          <InputLabel
            label={getMessage('tasks.task.realizer')}
            showReset={!!realizerState}
            resetButtonText={getMessage('remove')}
            onReset={() => {
              changeParam('realizer_id', '');
              setRealizerState(null);
            }}
          />
          <SearchableDropdown
            className="w-full"
            query={useGetEmployeesQuery}
            onSelect={(id) => changeParam('realizer_id', id)}
            initialState={realizerState}
            ariaLabel={getMessage('form.ariaLabel.realizer')}
          />
        </div>
        {/* @ts-ignore */}
        {result.isError && <FormNotice noticeClassName="col-span-full" type="error" message={result.error.data?.errors || getMessage('form.error')} />}
      </section>
      <section className="w-full border-b py-4">
        <p className="text-text-md-semibold text-typography-dark">
          {getMessage('tickets.ticket.reporterName')}
          <span className="text-text-md-medium ml-2">
            {reporter}
          </span>
        </p>
        <p className="text-text-md-semibold text-typography-dark">
          {getMessage('tickets.ticket.tenant')}
          <span className="text-text-md-medium ml-2">
            {tenantName || '-'}
          </span>
        </p>
        <p className="text-text-md-semibold text-typography-dark">
          {getMessage('tickets.ticket.venueAddress')}
          <span className="text-text-md-medium ml-2">
            {venueAddress || '-'}
          </span>
        </p>
        {hoa && (
          <p className="text-text-md-semibold text-typography-dark">
            {getMessage('tickets.ticket.homeownerAssociationName')}
            <span className="text-text-md-medium ml-2">
              {homeownerAssociationName || '-'}
            </span>
          </p>
        )}
      </section>
      <section className=" w-full tablet:flex tablet:justify-between tablet:items-end mb-4 lg:mb-0 pt-4">
        {tasks && tasks.length > 0 && (
          <div>
            <p className="text-text-md-semibold text-typography-dark ">
              {intl.formatMessage(
                { id: 'tickets.ticket.relatedTasks' },
                {
                  tasksCount: tasks.length,
                  taskLabel: tasksLabel(tasks.length)
                },
              )}
            </p>
            {tasks.map((task) => (
              <a
                key={task.id}
                className="underline cursor-pointer text-typography-dark block hover:text-main mobile:select-none"
                href={`/zadania/${task.id}`}
                tabIndex={0}
                aria-label={intl.formatMessage(
                  { id: 'tasks.task.goToTask' },
                  { taskNumberTitle: `${task.taskNumber} ${task.title}` },
                )}
              >
                {`Nr ${task.taskNumber} ${task.title}`}
              </a>
            ))}
          </div>
        )}
        <Button
          className="min-w-full tablet:min-w-fit lg:w-auto mt-4"
          onClick={() => navigate(`/zgloszenia/nowe-zadanie/${id}`)}
        >
          {getMessage('tickets.ticket.addTask')}
        </Button>
      </section>
    </div>
  );
};

export default TicketInfoCard;
